<template>
  <div>
    <!-- Table Container Card -->
    <b-card
      no-body
      class="mb-0"
    >
      <div class="mx-2 mb-2">
        <b-row>
          <b-col cols="12">
            <list-table-data
              :table-columns="tableColumns"
              :table-data="items"
              :redirect-edit="redirectEdit"
              :redirect-show="redirectShow"
              :origen="catalogoName"
              :show-icon-new="true"
              :companies="companies"
            />
          </b-col>
        </b-row>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
} from 'bootstrap-vue'
import ListTableData from '@/components/ListTableData.vue'
import { getAllUsers, getUsersByCompany } from '@/utils/usuarios'
import { getCompaniesSelect, getEmpresa } from '@/utils/companies'

export default {
  components: {
    ListTableData,
    BCard,
    BRow,
    BCol,
  },
  data() {
    return {
      usuario: JSON.parse(localStorage.getItem('userData')),
      catalogoName: 'Usuario',
      redirectEdit: 'catalogos-usuario-show',
      redirectShow: 'catalogos-usuario-show',
      items: [],
      companies: [],
      tableColumns: [
        {
          label: 'Nombre',
          field: 'nombreCompleto',
        },
        {
          label: 'Email',
          field: 'email',
          tdClass: 'text-center',
          thClass: 'vgt-center-align',
        },
        {
          label: 'Rol',
          field: 'role',
          tdClass: 'text-center',
          thClass: 'vgt-center-align',
        },
        {
          label: 'Empresa',
          field: 'empresa',
          tdClass: 'text-center',
          thClass: 'vgt-center-align',
        },
        {
          label: 'Estado',
          field: 'active',
          tdClass: 'text-center',
          thClass: 'vgt-center-align',
        },
        {
          label: 'Acciones',
          field: 'acciones',
          tdClass: 'text-center',
          thClass: 'vgt-center-align',
        },
      ],
    }
  },
  async beforeMount() {
    try {
      if (this.usuario.role === 'superadmin') this.items = await getAllUsers(true)
      else this.items = await getUsersByCompany(this.usuario.idCompany)

      if (this.items === null) this.items = []
      if (this.usuario.role === 'superadmin') this.companies = await getCompaniesSelect()
      else {
        const company = await getEmpresa(this.usuario.idCompany)
        this.companies = [{ value: company.id, text: company.name }]
      }
    } catch (err) {
      console.error(`Error en cargar ${this.catalogoName}`, err)
      this.items = []
    }
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
